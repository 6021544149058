<template>
    <div>
      <div v-if="propertyList.length <= 0" style="margin: auto">
        <EmptyState  :emptyData="emptyDataObject"/>
      </div>
      <div class="m-auto text-center mt-4" v-if="loadingProperties"><PageLoader/></div>
      <div class="row" v-else>         
          <div class="col-md-3 col-sm-12" style="margin-bottom: 30px;" v-for="(property, index) in propertyList"
                :key="index">
              <div class="property-preview-holder" @click="propertyDetails(property)">
                <p class="prop-status" :class="['prop-status', property.status == 'Vacant' ? 'vacant-status' : '', property.status == 'Occupied' ? 'occupied-status' : '']">{{ property.status }}</p>
                <div>
                    <img class="preview-img" src="../assets/images/prop1.svg" alt="image">
                </div>
                <div class="prop-preview-details">
                    <h5 v-transform="'uppercase'">{{ property.title }}</h5>
                    <h6 v-if="property">{{ property.city }}, {{ property.state }}</h6>
                    <h4>₦{{ Number(property.price).toLocaleString() }}</h4>
                    <small>{{ property.bedrooms }} bedrooms | {{ property.bathrooms }} bathrooms  <span style="padding-left:4px" v-if="property.toilets">  | {{ property.toilets }} toilets</span></small>
                    <p v-if="property.smartAgentObject">Listed by: {{ getFirstLetter(property.smartAgentObject.firstname) }}. {{ property.smartAgentObject.lastname }} | <span>For {{ property.businesstypename }}</span> </p>
                    <p v-else>Listed by: <span style="color: #0033ea;">Shelta</span> | <span>For {{ property.businesstypename }}</span> </p>
                </div>
              </div>
          </div>

      </div>
    </div>
</template>


<script>
import FooterMain from "../views/FooterMain";
import Skeleton from "../components/SkeletonLoader.vue";
import Header from "../views/Header";
import PageLoader from "../components/PageLoader.vue";
import { Service } from "../store/service";
import { getFirstLetter } from './helpers';
import EmptyState from './EmptyState.vue'
import $ from "jquery";
const Api = new Service();

export default {
  name: "PropertyListNew",
  components: {
    Header,
    FooterMain,
    Skeleton,
    PageLoader,
    EmptyState
  },
  props: {
    propertyList: {
      type: Array,
      required: true,
    },
    // loading: {
    //   type: Boolean,
    //   required:true,
    // }
  },
  data() {
    return {
      loadingProperties: false,
      emptyDataObject: {
        header: "No Property",
        option: "All property have be rented or sold out, check again later"
      }
    };
  },
  directives: {
    transform(el, binding) {
      const transformType = binding.value;
      if (transformType === 'uppercase') {
        el.textContent = el.textContent.toUpperCase();
      } else if (transformType === 'lowercase') {
        el.textContent = el.textContent.toLowerCase();
      }
    },
  },
  mounted() {
   
  },
  methods: {
    propertyDetails(prop) {
      this.$router.push("/property-details");
      localStorage.setItem("__propertyDetails", JSON.stringify(prop))
      console.log("property detail", prop)
    },
    getFirstLetter,
    
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;


    // PROPERTY CARD PREVIEW STARTS
.property-preview-holder {
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  position: relative;
}

.prop-status {
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 4px 12px;
  background: $white;
  color: $asheGrey;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  border-radius: 50px;
  border: 1px solid #b4bddd;
}

.status-text {
    background: $white;
    border-radius: 50px;
    position: absolute;
    left: 6%;
    top: 6%;
    padding: 4px 12px;
    span {
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: $asheGrey;
    }
}
.image-container {
    max-width: 330px; /* Set the desired width */
    // height: auto;
    height: 193px;
    overflow: hidden
}
.preview-img {
    border-radius: 10px;
    // max-width: 325.33px;
    min-height: 93px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prop-preview-details {
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #555C74;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
    color: #2B3352;
  }
  small {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #555C74;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    text-transform: capitalize;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #3E4958;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: #EA0033;
    }
  }
}

.vacant-status {
  background: #3ec250;
  color: #ffffff;
}
.occupied-status {
  background: #EA0033;
  color: #ffffff;
}
// PROPERTY CARD PREVIEW ENDS
</style>